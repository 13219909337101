import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { facultyLogout } from "../redux/action/facultyAction";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const drawerWidth = 240;

const Main = ({ open }) => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      padding: 3,
      transition: "margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      marginLeft: open ? `${drawerWidth}px` : "0px",
    }}
  />
);

const AppBar = ({ open, handleDrawerOpen }) => (
  <MuiAppBar
    position="fixed"
    sx={{
      transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      marginLeft: open ? `${drawerWidth}px` : "0px",
      width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
    }}
  >
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div">
        Cognitive Castle
            </Typography>
    </Toolbar>
  </MuiAppBar>
);

const DrawerHeader = ({ handleDrawerClose }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      px: 1,
      ...drawerWidth,
    }}
  >
    <IconButton onClick={handleDrawerClose}>
      <ChevronLeftIcon />
    </IconButton>
  </Box>
);

const Home = () => {
  const store = useSelector((store) => store);
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  // const [open, setOpen] = useState(false);

  useEffect(() => {
    if (store.faculty.faculty.faculty.name) {
      setName(store.faculty.faculty.faculty.name);
    }
  }, [store.faculty.faculty.faculty.name]);

  const handleDrawerOpen = () => {
    dispatch({ type: "DRAWER_OPEN", payload: true });
  };

  const handleDrawerClose = () => {
    dispatch({ type: "DRAWER_OPEN", payload: false });
  };

  const logoutHandler = () => {
    dispatch(facultyLogout());
    history.push("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={store.faculty.isOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Drawer
        variant="persistent"
        anchor="left"
        open={store.faculty.isOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
            padding: "5px",
          }}
        >
          <h5>Cognitive Castle</h5>
          <DrawerHeader handleDrawerClose={handleDrawerClose} />
        </div>
        <Divider />
        <List>
          {[
            "Update Profile",
            "Update Password",
            "Upload Notes",
            "Create Routines",
            "Upload Videos",
            "Answer Doubt",
            "Create Announcement",
          ].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                component={Link}
                to={
                  index === 0
                    ? "/faculty/updateProfile"
                    : index === 1
                      ? "/faculty/updatePassword"
                      : index === 2
                        ? "/faculty/uploadNotes"
                        : index === 3
                          ? "/faculty/createRoutines"
                          : index === 4
                            ? "/faculty/uploadVideos"
                            : index === 5
                              ? "/faculty/chat"
                              : "/announcement"
                }
              >
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={logoutHandler}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={store.faculty.isOpen}></Main>
    </Box>
  );
};

export default Home;
