import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import HomeHelper from "../../Components/HomeHelper";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Audio } from "react-loader-spinner";
import classnames from "classnames";
import url from "../../redux/utils/url";
import "./Chat.css";
import { useRef } from "react";

const socket = io("https://new-server.cognitive-castle.tech");

const ChatComponent = () => {
  const store = useSelector((store) => store);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const classId = store.student.student.student.year; // Get class ID automatically or from user selection
  const messagesEndRef = useRef(null);

  const formattedTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`https://new-server.cognitive-castle.tech/api/chat/messages?classId=${classId}`)
      .then((response) => response.json())
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
    console.log(messages);
    setLoading(false);

  }, [classId]);

  useEffect(() => {
    // Emit 'joinChat' event upon component mount
    socket.emit("joinChat", {
      classId,
      username: store.student.student.student.name,
    });
    socket.on("newMessage", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto", block: "end" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFileChange = async(e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        console.log(imagePreview);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const clearImagePreview = () => {
    setSelectedFile(null);
    setImagePreview(null);
  };

  const isInputValid = () => {
    return inputMessage.trim() !== "" || selectedFile !== null;
  };

  const renderMessageContent = (message) => {
    if ( message.image && message.message) {
        return (
         
           
            <div style={{display:'flex',flexDirection:'column'}}>
           
            <img src={message.image} alt="Uploaded" style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }} />
            <p>{message.message}</p>

            </div>
         
        );
      }
      if ( message.image) {
        return (
         
           
            <img src={message.image} alt="Uploaded" style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }} />

            
        );
      } else if (message.message) {
        return (
        
            <p>{message.message}</p>
        
        );
      } 
      return null;
  };

  const sendMessage = () => {
   // console.log(imagePreview);
    if (!isInputValid()) return;

    const messageData = {
      classId,
      message: inputMessage,
      senderId: store.student.student.student._id,
      senderName: store.student.student.student.name,
      senderRegistrationNumber:
        store.student.student.student.registrationNumber,
        image: imagePreview,
 
    };
    socket.emit("sendMessage", {
      messageData,
    });
    //console.log(messageData);
    setInputMessage("");
    clearImagePreview();
  };

  const containerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    opacity: loading ? 0.6 : 1,
    pointerEvents: loading ? "none" : "auto",
  };

  const cardStyles = {
    width: "90vw",
    height: "100vh",
    borderRadius: "10px",
  };

  const headerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f0f0f0 !important",
  };

  const bodyStyles = {
    display: "flex",
    flexDirection: "column", // Reverse the column direction to start from the end
    height: "60vh", // Example height for container
    overflowY: "auto", // Enable scrolling
    border: "1px solid #ccc", // Example border for container
    padding: "10px",
    backgroundColor: "#f0f0f0 ",
    // Example padding for container
  };

  const messageStyles = {
    display:"flex",
    flexDirection:"column",
    padding: "8px",
    marginBottom: "5px",
    borderRadius: "5px",
    maxWidth: "50%",
    height:"auto",
    wordWrap: "break-word", // Allow long words to break and wrap onto the next line
    overflowWrap: "break-word", // Alternative for word wrapping
    //wordBreak: "break-word", // Another alternative for word wrapping
    // Limit message width
  };

  const mineMessageStyles = {
    backgroundColor: "#007bff",
    color: "white",
    alignSelf: "flex-end",
  };

  const theirMessageStyles = {
    backgroundColor: "#f0f0f0",
    color: "black",
    alignSelf: "flex-start",
  };

  const footerStyles = {
    padding: "10px",
    borderTop: "1px solid #ccc",
  };

  const inputGroupStyles = {
    width: "100%",
  };

  const sendButtonStyles = {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  };

  return (
    <div>
      {store.student.isAuthenticated ? (
        <>
          <HomeHelper />
          {loading && (
            <div
              className="spinner-container"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Audio
                ariaLabel="loading"
                radius="9"
                color="green"
                height={80}
                width={80}
              />
            </div>
          )}
          <div className="rounded-3" style={containerStyles}>
            <div style={cardStyles} className="mt-5 rounded-30">
              <div
                className="card-header bg-success text-white"
                style={headerStyles}
              >
                <h2 className="mb-0">
                  Class:{store.student.student.student.year}
                </h2>
                {/*console.log(store.student)*/}
              </div>
              <div style={bodyStyles} className="card-body ">
                {messages.map((message, index) => (
                   
                  <div
                    key={index}
                    className={`message ${
                      message.senderId === store.student.student.student._id
                        ? "mine"
                        : "theirs"
                    }`}
                    style={messageStyles}
                  // style={{display:'flex',flexDirection:'column'}}
                  >
                    {message.senderId !== store.student.student.student._id ? (
                        
                      <p className="sender-name" >
                        {message.senderRegistrationNumber[0] === 'F' ? '(Teacher)' : ''}
                        {message.senderRegistrationNumber[0] === 'A' ? '(Admin)' : ''}
                        {message.senderName}:</p>
                    ) : (
                     'Me: '
                    )}
                    {renderMessageContent(message)}
                  </div>
                 
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div style={footerStyles} className="card-footer">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendMessage();
                  }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                      placeholder="Type a message..."
                      style={{
                        minWidth: "100px",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        style={sendButtonStyles}
                      >
                        Send
                      </button>
                    </div>
                    <div className="input-group-append ml-2">
                      <label className="btn btn-secondary m-0">
                        Upload Image
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                    {imagePreview && (
                      <div className="input-group-append ml-2">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxHeight: "50px", maxWidth: "50px" }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={clearImagePreview}
                        >
                          Clear
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        history.push("/")
      )}
    </div>
  );
};

export default ChatComponent;
