//ERROR
export const SET_ERRORS = "SET_ERRORS"
export const SET_ERRORS_HELPER = "SET_ERRORS_HELPER"
export const SET_FLAG = "SET_FLAG"

//ADMIN
export const SET_ADMIN = "SET_ADMIN"
export const GET_SUBJECTS = "GET_SUBJECTS"



//FACULTY
export const SET_FACULTY = "SET_FACULTY"
export const FACULTY_UPDATE_PASSWORD = "FACULTY_UPDATE_PASSWORD"

//STUDENT
export const SET_STUDENT = "SET_STUDENT"
export const STUDENT_UPDATE_PASSWORD = "STUDENT_UPDATE_PASSWORD"
export const SET_OTP = "SET_OTP"


