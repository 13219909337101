import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import AdminHomeHelper from "../../Components/AdminHomeHelper"
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Audio } from "react-loader-spinner";
import classnames from "classnames";
import "../Student/Chat.css";
import { useRef } from "react";


const AdminChatComponent = () => {
  const store = useSelector((store) => store);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uniqueYears, setUniqueYears] = useState(["1","2","3","4","5","6","7","8","9","10","11","12","12+"]);
  const[classId,setclassId]=useState(null);
  const [error, setError] = useState({});
  const socket = io("https://new-server.cognitive-castle.tech");

 // const classId = store.student.student.student.year; // Get class ID automatically or from user selection
  const messagesEndRef = useRef(null);

  /*
  useEffect(() => {
    const subjects = store.faculty.faculty.faculty.subjectsCanTeach;
    const years = subjects.map((subject) => subject.year);
    const uniqueYearsSet = new Set(years);
    const uniqueYearsArray = Array.from(uniqueYearsSet);
    setUniqueYears(uniqueYearsArray);
  }, [store.faculty.faculty.faculty.subjectsCanTeach]);

*/

  useEffect(() => {
    setLoading(true);
    fetch(`https://new-server.cognitive-castle.tech/api/chat/messages?classId=${classId}`)
      .then((response) => response.json())
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
    console.log(messages);
    setLoading(false);

  }, [classId]);

  const formHandler=(e)=>{
    e.preventDefault();
    setLoading(true);
    fetch(`https://new-server.cognitive-castle.tech/api/chat/messages?classId=${classId}`)
      .then((response) => response.json())
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
     // https://new-server.cognitive-castle.tech
    console.log(messages);
    setLoading(false);
  }

  useEffect(()=>{
    if(classId){
    socket.emit("joinChat", {
        classId,
        username: store.admin.admin.name,
      });
    }
  },[classId])

  useEffect(() => {
    // Emit 'joinChat' event upon component mount    
    /*socket.emit("joinChat", {
      classId,
      username: store.faculty.faculty.faculty.name,
    });*/
    socket.on("newMessage", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

  });

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto", block: "end" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFileChange = async(e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        console.log(imagePreview);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const clearImagePreview = () => {
    setSelectedFile(null);
    setImagePreview(null);
  };

  const isInputValid = () => {
    return inputMessage.trim() !== "" || selectedFile !== null;
  };

  const renderMessageContent = (message) => {
    if ( message.image && message.message) {
        return (
            <div style={{display:'flex',flexDirection:'column'}}>
           
            <img src={message.image} alt="Uploaded" style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }} />
            <p>{message.message}</p>

            </div>    
        );
      }
      if ( message.image) {
        return (
            <img src={message.image} alt="Uploaded" style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }} />
        );
      } else if (message.message) {
        return (
            <p>{message.message}</p>
        );
      } 
      return null;
  };

  const sendMessage = () => {
   // console.log(imagePreview);
    if (!isInputValid()) return;

    const messageData = {
      classId,
      message: inputMessage,
      senderId: store.admin.admin.id,
      senderName:store.admin.admin.name,
      senderRegistrationNumber:
      store.admin.admin.registrationNumber,
        image: imagePreview,
 
    };
    socket.emit("sendMessage", {
      messageData,
    });
    //console.log(messageData);
    setInputMessage("");
    clearImagePreview();
  };

  const containerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    opacity: loading ? 0.6 : 1,
    pointerEvents: loading ? "none" : "auto",
  };

  const cardStyles = {
    width: "90vw",
    height: "100vh",
    borderRadius: "10px",
  };

  const headerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f0f0f0 !important",
  };

  const bodyStyles = {
    display: "flex",
    flexDirection: "column", // Reverse the column direction to start from the end
    height: "60vh", // Example height for container
    overflowY: "auto", // Enable scrolling
    border: "1px solid #ccc", // Example border for container
    padding: "10px",
    backgroundColor: "#f0f0f0 ",
    // Example padding for container
  };

  const messageStyles = {
    display:"flex",
    flexDirection:"column",
    padding: "8px",
    marginBottom: "5px",
    borderRadius: "5px",
    maxWidth: "50%",
    height:"auto",
    wordWrap: "break-word", // Allow long words to break and wrap onto the next line
    overflowWrap: "break-word", // Alternative for word wrapping
    //wordBreak: "break-word", // Another alternative for word wrapping
    // Limit message width
  };

  const mineMessageStyles = {
    backgroundColor: "#007bff",
    color: "white",
    alignSelf: "flex-end",
  };

  const theirMessageStyles = {
    backgroundColor: "#f0f0f0",
    color: "black",
    alignSelf: "flex-start",
  };

  const footerStyles = {
    padding: "10px",
    borderTop: "1px solid #ccc",
  };

  const inputGroupStyles = {
    width: "100%",
  };

  const sendButtonStyles = {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  };

  return (
    <div>
      {store.admin.isAuthenticated ? (
        <>
          <AdminHomeHelper />
          {loading && (
            <div
              className="spinner-container"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Audio
                ariaLabel="loading"
                radius="9"
                color="green"
                height={80}
                width={80}
              />
            </div>
          )}
          
          <div
            style={{
              width: "100%",
              opacity: loading ? 0.6 : 1,
              pointerEvents: loading ? "none" : "auto",
              marginBottom: "40px",
            }}
          >

        <div className="row mt-1 " style={{marginLeft:'35vw'}}>
            <div className="col-md-4">
            <form form-inline noValidate onSubmit={formHandler}>
                  <div className="form-group">
                    <label htmlFor="classId">Year</label>                   
                      <select
                        onChange={(e) => setclassId(e.target.value)}
                        className={classnames("form-control", {
                          "is-invalid": error.setclassId,
                        })}
                        id="classId"
                      >
                        <option>Select</option>
                        {uniqueYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                
                    {error.setclassId && (
                      <div className="invalid-feedback">
                        {error.setclassId}
                      </div>
                    )}
                  </div>

                  <div class="row justify-content-center">
                    <div class="col-md-1">
                      {loading && (
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      )}
                    </div>
                  </div>
                  
                </form>
           </div>
           </div>

       { classId?(
          <div className="rounded-3" style={containerStyles}>
            <div style={cardStyles} className="mt-5 rounded-30">
              <div
                className="card-header bg-success text-white"
                style={headerStyles}
              >
                <h2 className="mb-0">
                  Class:{classId}
                </h2>
                {/*console.log(store.student)*/}
              </div>
              <div style={bodyStyles} className="card-body ">
                {messages.map((message, index) => (
                   
                  <div
                    key={index}
                    className={`message ${
                      message.senderId === store.admin.admin.id
                        ? "mine"
                        : "theirs"
                    }`}
                    style={messageStyles}
                  // style={{display:'flex',flexDirection:'column'}}
                  >
                    {message.senderId !== store.admin.admin.id ? (
                        
                      <p className="sender-name" >
                        {message.senderRegistrationNumber[0] === 'F' ? '(Teacher)' : ''}
                        {message.senderRegistrationNumber[0] === 'A' ? '(Admin)' : ''}
                        {message.senderName}:</p>
                    ) : (
                     'Me: '
                    )}
                    {renderMessageContent(message)}
                  </div>
                 
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div style={footerStyles} className="card-footer">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendMessage();
                  }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                      placeholder="Type a message..."
                      style={{
                        minWidth: "100px",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        style={sendButtonStyles}
                      >
                        Send
                      </button>
                    </div>
                    <div className="input-group-append ml-2">
                      <label className="btn btn-secondary m-0">
                        Upload Image
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                    {imagePreview && (
                      <div className="input-group-append ml-2">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxHeight: "50px", maxWidth: "50px" }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={clearImagePreview}
                        >
                          Clear
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
):null}
          </div>
                    
        </>
      ) : (
        history.push("/")
      )}
    </div>
  );
};

export default AdminChatComponent;
